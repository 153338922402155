import { render, staticRenderFns } from "./Form.vue?vue&type=template&id=3bb486e8&scoped=true&lang=pug&"
import script from "./Form.vue?vue&type=script&lang=js&"
export * from "./Form.vue?vue&type=script&lang=js&"
import style0 from "./Form.vue?vue&type=style&index=0&module=true&lang=css&"
import style1 from "./Form.vue?vue&type=style&index=1&id=3bb486e8&lang=scss&scoped=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "3bb486e8",
  null
  
)

export default component.exports