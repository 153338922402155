<template lang="pug">
.h-auto.flex.w-full
  .vx-col.mx-auto.self-center(
    class="sm:w-1/1 md:w-1/2 lg:w-3/4 xl:w-3/6"
  )
    vx-card
      form
        vs-row(
          vs-w="12"
        )
          vs-col(
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-lg="10"
            vs-sm="10"
            vs-xs="12"
          )
            span.text-data(
              class="ml-4 mt-1"
            ) {{ formTitle }}
          vs-col(
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="flex-end"
            vs-lg="2"
            vs-sm="2"
            vs-xs="12"
          )
        vs-divider
        div(
          class="vx-row"
        )
          div(
            class="vx-col sm:w-1/2 w-full mb-2"
          )
            vs-input.w-full(
              name="first_name"
              v-validate="'required|alpha_spaces'"
              :data-vv-as="$t('datauser.first_name')"
              icon="icon icon-user"
              icon-pack="feather"
              :label-placeholder="$t('datauser.first_name')"
              v-model="users.first_name"
              autocomplete="off"
            )
            span.text-danger.text-sm(
              v-show="errors.has('first_name')"
            ) {{ errors.first('first_name') }}
          div(
            class="vx-col sm:w-1/2 w-full mb-2"
          )
            vs-input.w-full(
              name="last_name"
              v-validate="'required|alpha_spaces'"
              :data-vv-as="$t('datauser.last_name')"
              icon="icon icon-user"
              icon-pack="feather"
              :label-placeholder="$t('datauser.last_name')"
              v-model="users.last_name"
              autocomplete="off"
            )
            span.text-danger.text-sm(
              v-show="errors.has('last_name')"
            ) {{ errors.first('last_name') }}
        div(
          class="vx-row"
        )
          div(
            class="vx-col sm:w-1/2 w-full mb-2"
          )
              label(
                class="vs-select--label"
              ) {{ $t('users.roles') }}
              v-select(
                :options="profiles"
                label="name"
                name="role"
                :data-vv-as="$t('users.role')"
                v-validate="'required'"
                :clearable="false"
                :placeholder='$t("users.roles")'
                v-model="profile"
                autocomplete="off"
              )
                template(
                  slot="option"
                  slot-scope="option"
                ) {{ option.name | capitalize }}
              span.text-danger.text-sm(
                v-show="errors.has('role')"
              ) {{ errors.first('role') }}
          div(
            class="vx-col sm:w-1/2 w-full mb-2"
          )
              label(
                class="vs-select--label"
              ) {{ $t('users.status') }}
              v-select(
                :options="statuses"
                label="name"
                name="role"
                :data-vv-as="$t('users.status')"
                v-validate="'required'"
                :clearable="false"
                :placeholder='$t("users.status")'
                v-model="status"
                autocomplete="off"
              )
                template(
                  slot="option"
                  slot-scope="option"
                ) {{ option.name | capitalize }}
              span.text-danger.text-sm(
                v-show="errors.has('role')"
              ) {{ errors.first('role') }}
        div(
          class="vx-row"
        )
          div(
            :class="classEmail"
          )
            vs-input.w-full(
              name="email"
              v-validate="'required|email|min:3'"
              :data-vv-as="$t('users.email')"
              :label-placeholder="$t('users.email')"
              v-model="users.email"
              icon-pack="feather"
              icon="icon icon-at-sign"
              autocomplete="off"
            )
            span.text-danger.text-sm(
              v-show="errors.has('email')"
            ) {{ errors.first('email') }}
          div(
            v-if="isCurrentCompany()"
            :class="`vx-col sm:w-1/2 w-full mb-2 ${$style.mbs3}`"
          )
            label(
              class="vs-select--label"
            ) {{ $t('users.company') }}
            v-select(
              :options="companies"
              label="name"
              :clearable="false"
              v-model="company"
              :placeholder='$t("users.company")'
              autocomplete="off"
            )
              template(
                slot="option"
                slot-scope="option"
              ) {{ option.name | capitalize }}
            span.text-danger.text-sm(
              v-show="errors.has('company')"
            ) {{ errors.first('company') }}
        div(
          class="vx-row"
        )
          div(
            class="vx-col sm:w-1/2 w-full mb-2"
            v-if="!$route.params.id"
          )
            vx-input-group(
              class="mb-base"
            )
              vs-input.w-full.mt-6.no-icon-border(
                ref="password_d"
                name="password_d"
                icon-pack="feather"
                icon="icon icon-lock"
                v-validate="'required|min:7'"
                v-model="users.password"
                autocomplete="off"
                readonly
                :data-vv-as="$t('auth.password')"
                :type="passwordFieldType"
                :label-placeholder="$t('auth.password')"
                onfocus="this.removeAttribute('readonly');"
              )
              template(
                slot="append"
              )
                div(
                  class="append-text btn-addon btn-margin-3"
                )
                  a(
                    class="vs-component vs-button vs-button-primary vs-button-filled includeIcon includeIconOnly"
                    color="primary"
                    style="color: white !important"
                    vslor="primary"
                    @click.prevent="switchVisibility"
                  )
                    i(
                      :class="iconPassword"
                    )
            span.text-danger.text-sm(
              v-show="errors.has('password_d')"
            ) {{ errors.first('password_d') }}
          div(
            class="vx-col sm:w-1/2 w-full mb-2"
            v-if="!$route.params.id"
          )
            vx-input-group(
              class="mb-base"
            )
              vs-input.w-full.mt-6.no-icon-border(
                name="password_confirmed_d"
                v-model="users.password2"
                v-validate="'required|confirmed:password_d'"
                autocomplete="off"
                icon-pack="feather"
                icon="icon icon-lock"
                readonly
                onfocus="this.removeAttribute('readonly');"
                :data-vv-as="$t('auth.password')"
                :type="passwordFieldType2"
                :label-placeholder="$t('users.password2')"
              )
              template(
                slot="append"
              )
                div(
                  class="append-text btn-addon btn-margin-3"
                )
                  a(
                    class="vs-component vs-button vs-button-primary vs-button-filled includeIcon includeIconOnly"
                    color="primary"
                    style="color: white !important"
                    vslor="primary"
                    @click.prevent="switchVisibilityConfirm"
                  )
                    i(
                      :class="iconPassword2"
                    )
            span.text-danger.text-sm(
              v-show="errors.has('password_confirmed_d')"
            ) {{ errors.first('password_confirmed_d') }}
        div(
          class="flex justify-between text-center mt-5"
        )
          vs-button.style-buttom.space-buttom.buttom-save(
            color="primary"
            type="filled"
            @click.prevent="$router.go(-1)"
          ) {{$t('users.behind')}}
          vs-button.style-buttom.space-buttom.buttom-save(
            color="primary"
            type="filled"
            @click.prevent="save"
          ) {{$t('devices.save')}}
</template>
<script>
import HTTP from "@/http-common"
import UsersServices from "@/api/users/Users"
import StatusServices from "@/api/status/Status"
import ErrorNotify from "@/components/errors/Errors"
import SelectDocument from "@/components/dataType/SelectDocument"
import SelectCompany from "@/components/dataType/CompanySelect"
import roleSelect from "@/components/dataType/SelectGroup"
import { mapGetters } from "vuex"

const NAME = "UserForm"

export default {
  name: NAME,
  components: {
    "vue-document": SelectDocument,
    "vue-roles": roleSelect,
    "vue-company": SelectCompany
  },
  data() {
    return {
      classEmail: null,
      users: {},
      document_type: null,
      roles: null,
      company: [],
      companies: [],
      passwordFieldType: "password",
      iconPassword: "fas fa-eye-slash",
      passwordFieldType2: "password",
      iconPassword2: "fas fa-eye-slash",
      country: null,
      depot: [],
      depots: [],
      profiles: [],
      profile: [],
      statuses: [],
      status: []
    }
  },
  computed: {
    ...mapGetters("auth", ["currentUser", "currentRol"]),
    formTitle() {
      if (this.$route.params.id) {
        return this.$t("users.add-edit")
      } else {
        return this.$t("users.add-user")
      }
    }
  },
  watch: {
    "$i18n.locale"() {
      this.$validator.reset()
    }
  },
  methods: {
    switchVisibility() {
      this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password"
      this.iconPassword = this.iconPassword === "fas fa-eye-slash" ? "fas fas fa-eye " : "fas fa-eye-slash"
    },
    switchVisibilityConfirm() {
      this.passwordFieldType2 = this.passwordFieldType2 === "password" ? "text" : "password"
      this.iconPassword2 = this.iconPassword2 === "fas fa-eye-slash" ? "fas fas fa-eye " : "fas fa-eye-slash"
    },
    getCompany() {
      HTTP.get("/v1/companies/companies/").then(response => {
        response.data.forEach(row => {
          this.companies.push({
            id: row.id,
            name: row.company
          })
        })
      })
    },
    isCurrentCompany() {
      if (
        this.currentRol[0] === "administrator"
      ) {
        this.classEmail = "vx-col sm:w-1/2 w-full mb-2"
        return true
      } else {
        this.classEmail = "vx-col sm:w-1/1 w-full mb-2"
        return false
      }
    },
    getStatus() {
      StatusServices.getStatus('V1')
      .then(response => {
        response.data.forEach(row => {
          this.statuses.push({
            id: row.id,
            name: row.status
          })
        })
      })
      .catch(response => {
        ErrorNotify.formsErrors({
          data: response.response,
          ob: this
        })
      })
    },
    getRoles() {
      HTTP.get("/v1/roles/roles/").then(response => {
        response.data.forEach(row => {
          this.profiles.push({
            id: row.id,
            name: row.slug
          })
        })
      })
    },
    setProfile (data) {
      for(let i=0; i < this.profiles.length; i++) {
        let index = data.indexOf(this.profiles[i].name)
        if (index >= 0) {
          this.profile.push({
            id: this.profiles[i].id,
            name: this.profiles[i].name
          })
        }
      }
    },
    getDataUsers() {
      if (this.$route.params.id) {
        UsersServices.getUserData(this.$route.params.id)
          .then(response => {
            this.users = response.data[0]
            if (response.data[0].company != null) {
              this.company.push(response.data[0].company)
            }
            if (response.data[0].status != null) {
              this.status.push(response.data[0].status)
            }
            this.setProfile(response.data[0].rol)
          })
          .catch(response => {
            ErrorNotify.formsErrors({
              data: response.response,
              ob: this
            })
          })
      }
    },
    save() {
      this.$validator.validate().then(result => {
        if (result) {
          if (this.$route.params.id) {
            this.users.company_id = this.company.id
            this.users.status_id = this.status.id
            this.users.roles = [this.profile.id]
            UsersServices.updateUsers(this.users)
              .then(() => {
                this.$vs.notify({
                  text: this.$t("Depots.petition"),
                  color: "#64cc52",
                  iconPack: "feather",
                  icon: "icon-check-circle"
                })
                this.$router.go(-1)
              })
              .catch(response => {
                this.$vs.notify({
                  text: this.$t(`errors.${response.response.data[0].message}`),
                  color: "#64cc52",
                  iconPack: "feather",
                  icon: "icon-check-circle"
                })
              })
          } else {
            this.users.company_id = this.company.id
            this.users.status_id = this.status.id
            this.users.roles = [this.profile.id]
            UsersServices.createUser(this.users)
              .then(() => {
                this.$vs.notify({
                  text: this.$t("Depots.petition"),
                  color: "#64cc52",
                  iconPack: "feather",
                  icon: "icon-check-circle"
                })
                this.$router.go(-1)
              })
              .catch(response => {
                this.$vs.notify({
                  text: this.$t(`errors.${response.response.data[0].message}`),
                  color: "#64cc52",
                  iconPack: "feather",
                  icon: "icon-check-circle"
                })
              })
          }
        }
      })
    }
  },
  created() {

  },
  mounted() {
    this.getStatus()
    this.getRoles()
    this.getCompany()
    setTimeout(() => {
      this.getDataUsers()
    }, 500)
  },
  destroyed() {
    this.$store.dispatch("userService/set_user", null)
  }
}
</script>
<style module>
.mbs3 {
  margin-top: -0.3em;
}
</style>
<style lang="scss" scoped>
.btn-margin-3 .vs-button {
  height: 69% !important;
  margin-top: 1.2em !important;
}
</style>